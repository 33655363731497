import React from 'react'

import Layout from "../../../components/Layout"
import CaseStudyNav from '../../../components/CaseStudyNav'
import CaseStudyStep from '../../../components/CaseStudyStep'

export default class DiagnosticStepTwo extends React.Component {
  render() {
    return (
      <div>
        <Layout>
          <CaseStudyNav 
            linkOneClass="aga-casestudy-link active"
            linkTwoClass="aga-casestudy-link"
            linkThreeClass="aga-casestudy-link"
          />        

          <CaseStudyStep
            title="Step #2"
            question="Are there other diagnostic or therapeutic approaches you might take before referring this patient to a specialist?"
            body={[
              <h3>Expert Opinion</h3>,
              <p>Jennifer continues to have symptoms and has negative tTG-IgA antibodies, only borderline-elevated CRP and calprotectin, and still no warning or alarming symptoms or signs, such as new anemia, rectal bleeding, nocturnal symptoms, or weight loss, any of which might suggest IBD. As a result, I would focus on the fact that she already has some insight into the connection of stress with her GI symptoms. While IBD is a possibility, these laboratory results do not provide evidence convincing or compelling enough to justify referral to a specialist. Building on this understanding I would ask Jennifer for a more detailed history to further explore the connection and to understand her coping strategies.  In particular, it would be worth seeing if Jennifer’s symptoms might be lessened by reducing stress and by introducing the FODMAPs diet, which, while difficult to follow, can be helpful in diagnosing IBS, a motility disorder with associated abdominal hypersensitivity that is impacted by both the gut microbiome and the brain-gut neuroaxis.</p>,
              <p>FODMAP stands for fermentable oligosaccharides, disaccharides, monosaccharides, and polyols, chemicals that ferment in the large intestine during digestion and that can provoke gastrointestinal symptoms such as abdominal distension, gas, diarrhea, and pain. Examples of foods high in FODMAPS include fructose (e.g., fruits, honey, and high-fructose corn syrup); lactose (e.g., dairy); inulin-type fructans (e.g., wheat, onion, and garlic); galactans (e.g., beans, lentils, and legumes like soy); and polyols (e.g., sweeteners containing sorbitol, mannitol, xylitol, maltitol, and stone fruits, such as avocado, apricots, cherries, nectarines, peaches, and plums).</p>, 
              <p>These compounds may trigger symptoms of IBS through two processes: 1) highly osmotic foods draw water into the small intestine, and 2) fermentation in the colonic bacteria creates gas, abdominal distention, bloating, and cramping. IBS is diagnosed using the ROME IV diagnostic criteria, which can be simplified to recurrent abdominal pain at least one day per week for the last three months together with two or more of the following: 1) pain associated with defecation, 2) change in stool frequency; 3) change in stool form.<sup>6</sup></p>, 
              <p>Because the FODMAPs diet is difficult to adhere to, the entire trial should be limited to four weeks. This is long enough to determine if IBS is indeed likely. In fact, if the diagnosis is in fact IBS, Jennifer may begin to feel better within a week. Given the complexity of the diet, she could download an app onto her phone that can assist with dietary choice. Meanwhile, for stress management I would ensure that she is getting sufficient sleep and exercise and offer her other recommendations such as yoga and mindfulness training.</p>,
            
              <section className="aga-container-short progression">
              <h2 className="aga-cs-question-title">Progression</h2>
              <p>When Jennifer returns to your office six weeks later for a follow-up examination, she reports being generally successful following the FODMAPs diet, lapsing only during her toddler’s birthday celebration and at one or two work-related lunches. However, she found it highly restrictive and is disappointed that it made no difference in her symptoms.</p>
              </section>,
              
              <section className="aga-container-short progression">
              <p><sup>6</sup> Rome Foundation. What’s New for Rome IV. Available at: <a href="https://theromefoundation.org/rome-iv/whats-new-for-rome-iv/">https://theromefoundation.org/rome-iv/whats-new-for-rome-iv/</a></p>
              </section>
              
            ]}
            slideLink="#"
            cmeLink="/cme"
            backLink="/steps/step-1"
            backText="Back to Step 1"
            nextLink="/steps/step-3"
            nextText="Go to Step 3"
          />
        </Layout>
      </div>
    )
  }
}